import "./src/styles/layout.css"
import CookieConsent from "react-cookie-consent"
import * as React from "react"
import { Link } from "gatsby"

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {element}
      {props.path.split("/")[1] === "uk" && (
        <CookieConsent
          style={{ alignItems: "center" }}
          buttonText="Accept Cookies"
          buttonClasses="orangeTransparent"
          buttonStyle={{
            background: "#FFFFFF",
          }}
        >
          We use cookies to ensure that we give you the best experience on our
          website. By continuing to use or access our website, you are
          consenting to our use of cookies in accordance with our{" "}
          <Link
            style={{ color: "white", textDecoration: "underline" }}
            to={`/uk/privacy-policy`}
          >
            privacy policy
          </Link>
          .
        </CookieConsent>
      )}
    </>
  )
}

export const shouldUpdateScroll = ({ routerProps }) => {
  const { disableScrollUpdate } = routerProps.location.state || false
  return !disableScrollUpdate
}
